<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Welcome back
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start" @submit.prevent="login">
                    <label>Email</label>
                    <input
                        class="form-control"
                        v-model="email"
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                    />
                    <label>Password</label>
                    <input
                        class="form-control"
                        v-model="password"
                      id="password"
                      type="password"
                      placeholder="Password"
                      name="password"
                    />
                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        type="sumit"
                        variant="gradient"
                        color="success"
                        full-width
                        >LOGIN
                      </soft-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src="@/assets/img/migration.jpg" alt="" style="width: 100%">
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import {post} from "@/utils/httpRequest";
import {decrypt} from "@/utils/encrypt";

export default {
  name: "SignIn",
  components: {
    SoftButton,
  },
  data: () => ({
    email: '',
    password: ''
  }),

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async login(){
      if(this.email.length > 2 && this.password.length > 2){
        const {data} = await post('auth/login',{email: this.email, password: this.password}, false)
        console.log("data",data)
        if(data.statusCode === 200){
          const role = decrypt(data.role);
          console.log("role",role)
          if(role === 'administrator'){
            console.log('enter dashboard')
              localStorage.setItem('migration-admin-token', data.token)
              localStorage.setItem('migration-admin-type', data.role)
              localStorage.setItem('migration-admin-uuid', data.uid)
              await this.$router.push({name: 'users'})

          }else{
            localStorage.clear()
            this.$swal({
              html: 'Rol error',
              icon: 'error',
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            })
          }
        }else{
          this.$swal({
            html: 'Don\'t have permission',
            icon: 'error',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
          })
        }
      }else{
        this.$swal({
          html: 'Email and password minimum characters allowed are 3',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    }
  },
};
</script>
